import Router from "vue-router";

import home from "@/view/home.vue";
import login from "@/view/login.vue";
const routerPush = Router.prototype.push;
Router.prototype.push = function (location) {
  return routerPush.call(this, location).catch((err) => {});
};

let routes = [
  {
    path: '/login',
    name: 'login',
    component: login,
  },
  {
    path: "/signin",
    name: "signin",
    component: () => import("@/view/signin.vue"),
  },
  {
    path: "/reset",
    name: "reset",
    component: () => import("@/view/reset.vue"),
  },
  {
    path: "/",
    name: "/home",
    component: home,
    meta: { showNav: true },
    redirect:"/home/index",
    children: [
      {
        path:"/home/index",
        name:"index",
        meta: { showNav: true,bannerSta:true },
        component:()=>import('@/view/home/index.vue')
      },
      {
        path:" /post",
        name:"post",
        meta: { showNav: true,bannerSta:true },
        component:()=>import('@/view/home/post.vue')
      },
      {
        path:"/gift",
        name:"gift",
        meta: { showNav: true,bannerSta:false },
        component:()=>import('@/view/gift/gift.vue')
      },
      {
        path:"/upgrades",
        name:"upgrades",
        meta: { showNav: true,bannerSta:false },
        component:()=>import('@/view/home/upgrades.vue')
      },
      {
        path:"/topup",
        name:"topup",
        meta: { showNav: true,bannerSta:false },
        component:()=>import('@/view/home/topup.vue')
      },
      {
        path:"/topup/refund",
        name:"refund",
        meta: { showNav: true,bannerSta:false },
        component:()=>import('@/view/home/refund.vue')
      },
      {
        path:"/home/userhome",
        name:"userhome",
        meta: { showNav: true,bannerSta:true },
        component:()=>import('@/view/home/userHome.vue')
      },
      {
        path:"/home/dynamicstate",
        name:"dynamicstate",
        meta: { showNav: true,bannerSta:true },
        component:()=>import('@/view/home/dynamicState.vue')
      },
      {
        path:"/home/love",
        name:"love",
        meta: { showNav: true,bannerSta:true },
        component:()=>import('@/view/home/love.vue')
      },
      {
        path:"/gift/giftinfo",
        name:"giftinfo",
        meta: { showNav: false,bannerSta:false },
        component:()=>import('@/view/gift/giftInfo.vue')
      },
      {
        path:"/gift/buygift",
        name:"buygift",
        meta: { showNav: false,bannerSta:false },
        component:()=>import('@/view/gift/buyGift.vue')
      },
      {
        path:"/home/send",
        name:"send",
        meta: { showNav: true,bannerSta:false },
        component:()=>import('@/view/home/send.vue')
      },
      {
        path:"/set/getAgreement",
        name:"getAgreement",
        meta: { showNav: true,bannerSta:false },
        component:()=>import('@/view/settings/getAgreement.vue')
      },
      {
        path:"/set",
        name:"set",
        meta: { showNav: false,bannerSta:false },
        component:()=>import('@/view/settings/set.vue'),
        redirect:"/set/index",
        children:[
          {
            path:"/set/index",
            name:"index",
            meta: { 
              setNum:1,
              showNav: false
             },
            component:()=>import('@/view/settings/index.vue')
          },
          {
            path:"/set/buytranslation",
            name:"buytranslation",
            meta: { 
              setNum:2,
              showNav: false
             },
            component:()=>import('@/view/settings/buyTranslation.vue')
          },
          {
            path:"/set/privacysettings",
            name:"privacysettings",
            meta: { 
              setNum:3,
              showNav: false
            },
            component:()=>import('@/view/settings/privacySettings.vue')
          },
          {
            path:"/set/notification",
            name:"notification",
            meta: { 
              setNum:4,
              showNav: false
            },
            component:()=>import('@/view/settings/notification.vue')
          },
          {
            path:"/set/userblacklist",
            name:"userblacklist",
            meta: { 
              setNum:5,
              showNav: false
            },
            component:()=>import('@/view/settings/userBlacklist.vue')
          },
          {
            path:"/set/accountsecurity",
            name:"accountsecurity",
            meta: { 
              setNum:6,
              showNav: false
             },
            component:()=>import('@/view/settings/accountSecurity.vue')
          },
          {
            path:"/set/emailinvitation",
            name:"emailinvitation",
            meta: { 
              setNum:7,
              showNav: false
            },
            component:()=>import('@/view/settings/emailInvitation.vue')
          },
          {
            path:"/set/linkinvitation",
            name:"linkinvitation",
            meta: { 
              setNum:8,
              showNav: false
            },
            component:()=>import('@/view/settings/linkInvitation.vue')
          },
        ]
      },
    ],
  },
  
];

let router = new Router({
  routes,
});

export default router;
