<!-- 切换语言弹窗 -->
<template>
    <div>
      <el-dialog class="language cursor" width="405px" :show-close="false" :visible.sync="languagePop">
        <div class="languageBox">
          <div class="popTit">
            <p>{{i18n.pop.TRANSLATORS}}</p>
            <div class="btnBox">
              <img @click="languagePop = false" style="width: 20px;height: 20px;margin-right: 20px;" src="../src/assets/icon/22.png" alt="">
            </div>
          </div>
          <div class="lanBox">
            <div class="languageList" v-for="(item,index) in languageList" :key="index" @click="switchSel(item,index)">
                <p :class="item.key == nowLanguage?'sel':''">{{item.value}}</p>
            </div>
          </div>
          <div class="btn">
              <div class="bgcolor" style="color: #FFFFFF;" @click="taglangfun">{{i18n.btn.verify}}</div>
              <div @click="languagePop = false">{{i18n.btn.cancel}}</div>
          </div>
        </div>  
      </el-dialog>
    </div>
</template>
<script>
export default {
  props:{
    type:{
      typeof:Number,
      default:1
    }
  },
  data() {
    return {
        languagePop:false,
        nowLanguage:localStorage.getItem('selLanguage'),
        languageList:[]
    };
  },
  computed: {
    i18n() {
      return this.$t("message");
    },
  },
  watch: {},
  methods: {
    // 选择语言
    switchSel(e,i){
      this.nowLanguage = e.key;
    },
    // 语言切换
    taglangfun() {
      if(this.type == 1){
        console.log(this.nowLanguage,'--------------')
        localStorage.setItem('selLanguage',this.nowLanguage);
        this._i18n.locale = this.nowLanguage;
        let locale = this._i18n.locale;
        window.localStorage.setItem("locale", locale);
      }else{
        this.$emit('render',this.nowLanguage);
      }
      this.languagePop = false;
      // window.location.reload();
    } 
  },
  beforeCreate() {}, //生命周期 - 创建之前
  created() {

  },
  beforeMount() {}, //生命周期 - 挂载之前
  mounted() {
    this.languageList = JSON.parse(localStorage.getItem('language'));
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {
  }, //如果页面有keep-alive缓存功能，激活
  deactivated() {}, //如果页面有keep-alive缓存功能，非激活
};
</script>
<style lang="less">
  .language{

  }
  .lanBox{
    height: 500px;
    overflow-y: scroll;
    scrollbar-width: none;
  }
  .languageBox{
    padding-bottom: 35px;
    background-color: #fff;
    .popTit{ 
      width: 100%;
      height: 62px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      color: #333333;
      border-radius: 5px 5px 0px 0px;
      background: linear-gradient(180deg, #FFD4EB 0%, #FFD4EB 100%);
      position: relative;
      .btnBox{
        position: absolute;
        right: 0;
        height: 62px;
        display: flex;
        align-items: center;
      }
    }
    .languageList{
      margin-top: 30px;
      p{
        width: 405px;
        height: 62px;
        color: #333333;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .sel{
        color: #FFFFFF;
        background: linear-gradient(180deg, #FF6FBC 0%, #D773F1 100%);
      }
    }
    .btn{
      display: flex;
      align-items: center;
      justify-content: center;
      div{
        width: 158px;
        height: 45px;
        border-radius: 30px;
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:nth-child(2){
          background: #E4E4E4;
          color: #525252;
          margin-left: 30px;
        }
      }
    }
  }
  
</style>