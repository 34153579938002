<!-- 头部 -->
<template>
    <div class="login">
       <div class="loginCon">
            <div class="loginLeft">
                <p>{{ i18n.login.welcome }}</p>
                <div>{{ i18n.login.introduce }}</div>
            </div>
            <div class="loginRight">
                <div class="selBox cursor" @click="taglangfun">
                    <img style="width: 29px;height: 24px;margin-left: 30px;" src="../assets/icon/16.png" alt="">
                </div>
                <p style="font-size: 36px;font-weight: 900;color: #0E0E0E;">{{ i18n.login.welcomelogin }}</p>
                <p style="margin-top: 5px;font-size: 18px;color: #3D3D3D;" class="cursor"
                    @click="$method.skip('./signin')">
                    <span>{{ i18n.login.nohave }}</span>
                    <span style="color: #E92E2E;">{{ i18n.login.register }}</span>
                </p>
                <el-input style="margin-top: 65px;" v-model="userName" :placeholder="i18n.login.username"></el-input>
                <el-input :type="pwdType" style="margin-top: 30px;" v-model="password" :placeholder="i18n.login.password">
                    <div slot="append">
                        <img v-if="eyeIcon" @click="passwordBtn(false,'text')" style="width: 25px;height: 25px;" src="../assets/icon/17.png" alt="">
                        <img v-if="!eyeIcon" @click="passwordBtn(true,'password')" style="width: 25px;height: 25px;" src="../assets/icon/18.png" alt="">
                    </div>
                </el-input>
                <div class="loginSet">
                    <el-checkbox v-model="checked" @change="rememberBtn">{{ i18n.login.remember }}</el-checkbox>
                    <p class="cursor" style="color: #999999;font-size: 14px;" @click="$method.skip('./reset')">{{ i18n.login.forgot }}</p>
                </div>
                <div class="loginBtn cursor" @click="loginBtn">{{ i18n.login.login }}</div>
            </div>
       </div>
       <p style="margin-top: 50px;color: #666666;font-size: 14px;">
            <span>{{ corporation }}</span>
            <span style="margin-left: 100px;">{{ corporationStr }}</span>
        </p>
        <div class="bottomBox">
            <div class="logo" v-html="jsContent.copy_right_logo"></div>
            <div class="bottomText"><p class="desc">{{ linkUrl }}</p></div>
        </div>
        <language ref="switchLanguage"></language>
    </div>
</template>
  
<script>
import language from '../../components/language.vue';
import { Bus } from '../main';
import axios from 'axios';

export default {
  components:{language},
  data() {
    //这里存放数据
    return {
        userName:'', // 用户名
        password:'', // 密码
        checked: true, // 是否记住密码
        eyeIcon:true, // 密码是否显示
        pwdType:'password', // 密码框类型
        configData:[],
        corporation:'',
        corporationStr:'',
        jsContent:'',
        linkUrl:''
    };
  },
  computed: {
    i18n() {
      return this.$t("message");
    },
  },
  watch: {},
  methods: {
    // 语言切换
    taglangfun() {
      this.$refs.switchLanguage.languagePop = true;
    },
    // 是否记住密码
    rememberBtn(e){
        this.checked = e;
    },
    // 密码显示和隐藏
    passwordBtn(show,type){
        this.eyeIcon = show;
        this.pwdType = type;
    },
    // 获取系统消息
    getConfig(){
        this.$postRequest("Common/getConfig").then((res) => {
            if(res.status == 200){
                this.configData = res.data;
                this.corporation = res.data[3].value;
                this.corporationStr = res.data[1].value;
            }
        });
    },
    // 登录
    loginBtn(){
        if(!this.userName){
            this.$method.message(this.i18n.login.username);
            return;
        }
        if(!this.password){
            this.$method.message(this.i18n.login.password);
            return;
        }
        if(this.checked){
            localStorage.setItem('userName', this.userName);
            localStorage.setItem('password', this.password);
        }
        let data = {
            account:this.userName,
            password:this.password
        }
        // 登录
        this.$postRequest("login/login",data).then((res) => {
            if(res.status == 200){
                localStorage.setItem('token',res.data.authToken);
                let userInfo = JSON.stringify(res.data.userInfo);
                localStorage.setItem('userInfo',userInfo);
                this.websocket.Init();
                this.$method.skip('./')
            }
        });
    },
    getRechargeParam(){
      this.$postRequest("/Recharge/getRechargeParam").then((res) => {
        let data = `https://www.wshtmltool.com/Get_info.js?mid=${res.data.mid}&corp=${res.data.corp}`
		this.fetchRemoteJS(data);
      });
    },
    fetchRemoteJS(data) {
      axios.get(data)
        .then(response => {
          this.jsContent = this.convertToObject(response.data);
          var myDate = new Date();
          this.linkUrl = `Copyright © ${myDate.getFullYear()} ${this.jsContent.copy_right_company} All Rights Reserved.`
        })
        .catch(error => {
          console.error('There was an error!', error);
        });
    },
    convertToObject(str) {
      // 通过分号分割字符串得到子字符串数组
      const parts = str.split(';');
      // 使用reduce函数处理数组，并构建对象
      var processedParts = parts.map(function(part) {
        return part.replace('var', '').trim(); // 使用 trim 移除两端的空格
      });
      return processedParts.reduce((acc, part) => {
        // 分割子字符串以找到第一个等号
        const [key, ...value] = part.split('=');
        // 将分割后的数组转换为字符串，并去除前后空格
        acc[key.trim()] = (value.join('=').trim()).replace(/^'|'$/g, '');
        return acc;
      }, {});
    }
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.getRechargeParam();
    // 获取本地用户名和密码
    if(localStorage.getItem('userName')){
        this.userName = localStorage.getItem('userName');
    }
    if(localStorage.getItem('password')){
        this.password = localStorage.getItem('password');
    }
    this.getConfig();
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，激活
  deactivated() {}, //如果页面有keep-alive缓存功能，非激活
};
</script>
<style lang="less" scoped>
  img{
    width: 100%;
  }
  .login{
    width: 100vw;
    height: 100vh;
    background-image: url('../assets/login.png');
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    .loginCon{
        height: 677px;
        border-radius: 10px;
        overflow: hidden;
        display: flex;
        .loginLeft{
            width: 508px;
            height: 677px;
            background-image: url('../assets/loginleft.png');
            background-size: 100% 100%;
            text-align: center;
            p{
                font-size: 72px;
                font-weight: bold;
                color: #333333;
                margin-top: 106px;
            }
            div{
                font-size: 24px;
                color: #333333;
                margin-top: 66px;
                padding: 0 80px;
                display: block;
                text-align: center;
            }
        }
        .loginRight{
            width: 538px;
            height: 677px;
            background-color: #fff;
            padding: 72px 47px 0;
            box-sizing: border-box;
            position: relative;
            .selBox{
                position: absolute;
                right: 25px;
                top: 25px;
                height: 24px
            }
            .loginSet{
                margin-top: 20px;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            .loginBtn{
                margin-top: 55px;
                width: 443px;
                height: 72px;
                font-size: 20px;
                font-weight: bold;
                border-radius: 36px;
                color: #FFFFFF;
                display: flex;
                align-items: center;
                justify-content: center;
                background: linear-gradient(180deg, #FF6FBC 0%, #D773F1 100%);
            }
        }
    }
  }

::v-deep .el-checkbox{
    .el-checkbox__input{
        .el-checkbox__inner{
            width: 20px;
            height: 20px;
            border-color: #DBDBDB;
            background: none;
            &::after{
                display: none;
            }
        }
    }
    .is-checked{
        .el-checkbox__inner{
            width: 20px;
            height: 20px;
            border: none;
            background-image: url('../assets/icon/43.png');
            background-size: 100% 100%;
            &::after{
                display: none;
            }
        }
    }
    .is_focus{
        .el-checkbox__inner{
            width: 20px;
            height: 20px;
            background: none;
            border-color: #DBDBDB !important;
            &::after{
                display: none;
            }
        }
    }
    .el-checkbox__label{
        font-size: 14px;
        color: #999999 !important;
    }
}

::v-deep .el-input{
    .el-input__inner{
        width: 444px;
        height: 72px;
        border-radius: 5px;
        background: #FFFFFF;
        box-sizing: border-box;
        border: 1px solid #333333;
        color: #333333;
        font-size: 18px;
    }
    .el-input-group__append{
        background: none;
        border: none;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
    }
}
.bottomBox{
    position: absolute;
    bottom: 0;
}
.bottomText{
    display: flex;
    text-align: center;
}
.logo{
    width: 234px;
    height: 68px;
    font-size: 15px;
    color: #FFFFFF;
    margin: 10px auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.desc{
    margin: 10px auto 0;
    color: #FFFFFF;
    font-size: 12px;
    text-align: center;
}
</style>