<template>
    <div>
        <el-dialog class="direct" width="638px" :show-close="false" :visible.sync="messagePop">
            <div class="directBox">
                <div class="popTit">
                    <p>{{titName}}</p>
                    <div class="btnBox">
                        <img @click="messagePopbtn" style="width: 20px;height: 20px;margin-right: 20px;" src="../src/assets/icon/22.png" alt="">
                    </div>
                </div>
                <div class="message">
                    <div class="messageBox" @scroll="handleScroll" ref="chatContent">
                        <div class="loading" v-if="load">
                            <el-button type="primary" :loading="true"></el-button>
                        </div>
                        <div class="messageList" v-for="(item,index) in list" :key="index">
                            <p class="time" v-if="index==0">{{$method.displayMessage(null,item.create_time)}}</p>
                            <p class="time" v-else>{{$method.displayMessage(list[index-1].create_time,item.create_time)}}</p>
                            <div class="centenrText color" v-if="item.type=='gift'">
                                <p v-html="item.content" style="text-align: center;"></p>
                            </div>
                            <div class="centenrText color" v-else-if="item.type=='setText'">
                                <p v-html="item.content" style="text-align: center;"></p>
                            </div>
                            <div class="Right" v-else-if="item.from_user.id == userInfo.user_id">
                                <div class="content">
                                    <div class="contentTit">
                                        <!-- <p>{{$method.timeFormat(item.create_time)}}</p> -->
                                        <!-- <p v-if="index==0">{{$method.displayMessage(null,item.create_time)}}</p>
                                        <p v-else>{{$method.displayMessage(list[index-1].create_time,item.create_time)}}</p> -->
                                        <p>{{item.from_user.nickname}}</p>
                                    </div>
                                    <div class="infoBox">
                                        <div class="info">
                                            <p v-if="item.type == 'text'">{{ item.content }}</p>
                                            <div class="infoPic" v-if="item.type=='image'">
                                                <div class="pic">
                                                    <el-image 
                                                        style="width: 120px;"
                                                        fit="scale-down"
                                                        :src="$imgurl + item.content"
                                                        @click="lookPic(item.content)"
                                                        :preview-src-list="picList" 
                                                        >
                                                    </el-image>
                                                </div>
                                            </div>
                                            <div class="infoPic" v-if="item.type=='video'">
                                                <div class="pic" @click="showVideobtn(item,index)">
                                                    <video :src="$imgurl + item.content"></video>
                                                </div>
                                            </div>
                                            <div class="infoPic" v-if="item.type=='music'">
                                                <div class="pic audio" @click="showAudiobtn(item,index)">
                                                    <audio :src="$imgurl + item.content"></audio>
                                                    <img src="../src/assets/icon/77.png" alt="">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="head">
                                    <img :src="$imgurl + item.from_user.avatar" alt="">
                                </div>
                            </div>
                            <div class="Left" v-else>
                                <div class="head">
                                    <img :src="$imgurl + item.from_user.avatar" alt="">
                                </div>
                                <div class="content">
                                    <div class="contentTit">
                                        <p>{{item.from_user.nickname}}</p>
                                        <!-- <p>{{$method.timeFormat(item.create_time)}}</p> -->
                                    </div>
                                    <div class="infoBox">
                                        <div class="info">
                                            <p v-if="item.type == 'text'">{{ item.content }}</p>
                                            <div class="infoPic" v-if="item.type=='image'">
                                                <div class="pic">
                                                    <el-image 
                                                        style="width: 120px;"
                                                        fit="scale-down"
                                                        :src="$imgurl + item.content" 
                                                        @click="lookPic(item.content)"
                                                        :preview-src-list="picList" 
                                                        >
                                                    </el-image>
                                                </div>
                                            </div>
                                            <div class="infoPic" v-if="item.type=='video'">
                                                <div class="pic" @click="showVideobtn(item,index)">
                                                    <video :src="$imgurl + item.content"></video>
                                                </div>
                                            </div>
                                            <div class="infoPic" v-if="item.type=='music'">
                                                <div class="pic audio" @click="showAudiobtn(item,index)">
                                                    <audio :src="$imgurl + item.content"></audio>
                                                    <img src="../src/assets/icon/76.png" alt="">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="transBtn" v-if="item.type == 'text'" style="margin-left: 10px;" @click="switchLanguage(item,index)">
                                            <img style="width: 29px;height: 24px; " src="../src/assets/icon/35.png" alt="">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="review">
                        <div class="controls">
                            <img v-if="userInfo.gender == 0" @click="addText" style="width: 30px;height: 30px;margin-right: 10px;" src="../src/assets/icon/74.png" alt="">
                            <img v-if="userInfo.gender == 0" @click="showText" style="width: 30px;height: 30px;margin-right: 10px;" src="../src/assets/icon/75.png" alt="">
                            <img @click="emoteShowbtn" style="width: 30px;height: 30px;margin-right: 10px;" src="../src/assets/icon/36.png" alt="">
                            <div class="uploadBox">
                                <!-- 上传图片 -->
                                <el-upload
                                    class="upload-demo"
                                    action="abcd"
                                    accept="image/*"
                                    :http-request="uploadFile"
                                    :show-file-list="false">
                                    <img style="width: 30px;height: 30px;" src="../src/assets/icon/37.png" alt="">
                                </el-upload>
                            </div>
                        </div>
                        <div class="inputBox">
                            <div class="emojiTextbox">
                                <input :style="{width:userInfo.gender == 0?'280px':'360px'}" type="text" v-model="text" @keydown.enter="senMsg">
                            </div>
                            <div class="slotBox" @click="senMsg">
                                <div class="searchBtn bgcolor">{{i18n.pop.send}}</div>
                            </div>
                        </div>
                        <div class="emojiBox cursor" v-if="emoteSta">
                            <div class="emojiList" v-for="(item,index) in emojiMaplist" :key="index" @click="getEmojis(item,index)">
                                <!-- <img :src="item.src" alt=""> -->
                                {{ item }}
                            </div>
                        </div>
                        <div class="textBox cursor" v-if="textShow">
                            <img src="../src/assets/icon/74.png" style="width: 30px;height: 30px;" alt="">
                            <div class="text" v-for="(item,index) in textList" :key="index" @click="sendText(item,index)">
                                <p>{{ item.content }}</p>
                                <img @click.stop="delText(item,index)" src="../src/assets/icon/62.png" alt=""></img>
                            </div>
                        </div>
                        <!-- <el-input v-model="input" :placeholder="i18n.pop.send2">
                            <div slot="append" class="slotBox" @click="sendMessage">
                                <div class="searchBtn bgcolor">{{i18n.pop.send}}</div>
                            </div>
                        </el-input> -->
                    </div>
                </div>
            </div>  
        </el-dialog>
        <language ref="switchLanguage" @render="render" :type="2"></language>
        <pop ref="popOpen" :titName="popName"></pop>
        <div class="videoBig">
            <el-dialog :show-close="false" :visible.sync="videoSta">
                <video style="width: 100%;height: auto;" controls :src="$imgurl + videoShow" ></video>
            </el-dialog>
        </div>
        <selectPop ref="add" :type="5" @add="add"></selectPop>
        <audio ref="audioPlayer" src=""></audio>
    </div>
</template>
<script>
import language from './language.vue';
import { message } from '@/locales/zh-CN';
import { Bus } from '../src/main';
import selectPop from './selectPop.vue';

export default {
    components:{
        language,selectPop
    },
    props:{
        titName:{
            type:String,
            default:'聊天'
        },
        myInfo:{
            type:Object,
            default:null
        },
        // list:{
        //     type:Array,
        //     default(){
        //         return []
        //     }
        // }
    },
    data() {
        return {
            scrollValue:0,  //滚动距离值
            userInfo:{},
            emoteSta:false,  // 表情框显示与隐藏
            text:'',
            token:localStorage.getItem('token'),
            picsendData:{},
            messagePop:false,
            input:"",
            list:[],
            form:{
                limit:20,
                page:1
            },
            loadingOlderMessages: true, // 是否正在加载旧消息
            message:[],
            type:'text',
            picList:[],
            nowLanguage:'',
            nowNumber:null,
            scrolls:true,
            load:false,
            scrollTop:null,
            popName:'',
            videoShow:'', // 预览视频
            videoSta:false, // 是否预览视频,
            sensitive:[],
            textShow:false,
            is_read:'',
            readData:'',
            textList:[],
            messageType:1,
            userId:{},
            messageTypeNum:1
        };
    },
    computed: {
        i18n() {
            return this.$t("message");
        },
    },
    watch: {
       
    },
    methods: {
        // 获取敏感词
        getSensitive(){
            this.$postRequest("Common/getSensitive").then((res) => {
                if(res.status == 200){
                    this.sensitive = (res.data.value).split(",");
                }
            });
        },
        // 放大展示视频
        showVideobtn(item,index){
            this.videoShow = item.content;
            this.videoSta = true;
        },
        showAudiobtn(item,index){
            const audio = this.$refs.audioPlayer;
            audio.src = this.$imgurl + item.content;
            audio.play();
        },
        // 添加快捷语
        addText(){
            this.$refs.add.popDirect = true;
            this.emoteSta = false;
            this.textShow = false;
        },
        add(data){
            // this.userInfo.tags.push(data);
            this.$postRequest("user/addQuickLanguage",{
                content:data
            }).then((res) => {
                if(res.status == 200){
                    this.$refs.add.popDirect = false;
                    this.getQuickLanguage();
                }
            });
        },
        // 获取快捷语
        getQuickLanguage(){
            this.$postRequest("user/getQuickLanguage").then((res) => {
                if(res.status == 200){
                    this.textList = res.data;
                }
            });
        },
        showText(){
            this.textShow = !this.textShow;
            this.emoteSta = false;
        },
        sendText(item,index){
            this.send(item.content,'setText');
            this.textShow = false;
        },
        delText(item,index){
            this.$postRequest("user/delQuickLanguage",{id:item.id}).then((res) => {
                if(res.status == 200){
                    this.textList.splice(index,1);
                }
            });
        },
        // 切换语言
        switchLanguage(item,index){
            this.$refs.switchLanguage.languagePop = true;
            this.nowLanguage = item;
            this.nowNumber = index;
        },
        render(data){
            let datas = {
                string:this.nowLanguage.content,
                language_type:data,
            }
            if(this.messageType == 4){
                datas.auth = 'open'
            }
            this.$postRequest("UserAuth/render",datas).then((res) => {
                if(res.status == 200){
                    this.list[this.nowNumber].content = '';
                    res.data.forEach((item,index)=>{
                        this.list[this.nowNumber].content += item.dst;
                    })
                }
            });
        },
        // 预览图片
        lookPic(pic){
            this.picList = [];
            this.picList.push(this.$imgurl + pic);
        },
        messageOpen(){
            console.log(this.messageType,this.userId)
            this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
            this.getSensitive();
            this.getQuickLanguage();
            this.messagePop = true;
            this.list = [];
            this.form.page = 1;
            if(this.messageType == 1){
                let userInfo = JSON.parse(localStorage.getItem('userInfo'));
                if(this.userId.create_user == userInfo.user_id){
                    this.getList(this.userId.friend_user_id);
                }else{
                    this.getList(this.userId.create_user);
                }
            }else if(this.messageType == 2){
                if(!this.userId.user_id){
                    this.getList(this.userId.id);
                }else{
                    this.getList(this.userId.user_id);
                }
            }else if(this.messageType == 3){
                this.getList(this.userId.to_user_id);
            }else if(this.messageType == 4){
                this.getList(this.userId.id);
            }
            let that = this;
            Bus.$on('is_read', (data) => {
                that.is_read = data.data.is_read;
                that.readData = data.data;
            })
        },
        messagePopbtn(){
            this.messagePop = false;
            this.scrolls = true;
            Bus.$emit('updateMount',true);
            let userInfo = JSON.parse(localStorage.getItem('userInfo'));
            let isread = {
                type: 'is_read',
                user_id:userInfo.user_id,
                time: (new Date()).getTime(),
            }
            if(this.messageType == 1){
                let userInfo = JSON.parse(localStorage.getItem('userInfo'));
                if(this.userId.create_user == userInfo.user_id){
                    isread.to_user = this.userId.friend_user_id;
                }else{
                    isread.to_user = this.userId.create_user;
                }
            }else if(this.messageType == 2){
                if(!this.userId.user_id){
                    isread.to_user = this.userId.id;
                }else{
                    isread.to_user = this.userId.user_id;
                }
            }else if(this.messageType == 3){
                isread.to_user = this.userId.to_user_id;
            }else if(this.messageType == 4){
                isread.to_user = this.userId.id;
            }
            this.websocket.Send(isread);
            this.userId = null;
        },
        // 显示表情
        emoteShowbtn(){
            this.emoteSta = !this.emoteSta;
            this.textShow = false;
        },
        // 输入表情
        getEmojis(event,index) {
            this.text += event;
            this.emoteSta = false;
        },
        // 发布
        senMsg() {
            let text = this.$method.filterHTMLTags(this.text);
            if(!text){
                this.$method.message(this.i18n.othor.tishi);
                return
            }
            let textStr = this.$method.replaceSensitiveWords(text,this.sensitive);
            this.send(textStr,'text');
            this.text = '';
        },
        send(content,type){
            let time = (new Date()).getTime();
            let id;
            if(this.messageType == 1){
                let userInfo = JSON.parse(localStorage.getItem('userInfo'));
                if(this.userId.create_user == userInfo.user_id){
                    id = this.userId.friend_user_id;
                }else{
                    id = this.userId.create_user;
                }
            }else if(this.messageType == 2){
                if(!this.userId.user_id){
                    id = this.userId.id;
                }else{
                    id = this.userId.user_id;
                }
            }else if(this.messageType == 3){
                id = this.userId.to_user_id;
            }else if(this.messageType == 4){
                id = this.userId.id;
            }
            console.log(this.userInfo)
            let data = {
                to_user:id,
                content:content,
                type:type,
                is_read:this.is_read,
                avatar:this.userInfo.avatar,
                nickname:this.userInfo.nickname,
                createtime:this.$method.getNowTime(),
                sendid:this.userInfo.user_id,
            }
            if(this.messageType == 1){
                data.friend_id = this.userId.friend_id
            }
            let that = this;
            this.$postRequest("mini/sendMessage",data).then((res) => {
                if(res.status == 200){
                    let strObj = {
                        content:content,
                        from_user:{
                            id:this.userInfo.user_id,
                            avatar:this.userInfo.avatar,
                            nickname:this.userInfo.nickname,
                        },
                        type:type,
                        create_time:this.$method.getNowTime()
                    }
                    this.list.push(strObj);
                    console.log(this.list)
                    that.$nextTick(() =>{
                        if (that.$refs.chatContent) {
                            that.$refs.chatContent.scrollTop = that.$refs.chatContent.scrollHeight;
                        }
                    })
                    let sendChat = {
                        to_user:id,
                        content:content,
                        type:type,
                        is_read:that.is_read,
                        avatar:that.userInfo.avatar,
						nickname:that.userInfo.nickname,
                        createtime:that.$method.getNowTime(),
                        sendid:that.userInfo.user_id,
                    } 
                    if(this.messageType == 1){
                        sendChat.friend_id = this.userId.friend_id
                    }
                    let simpleSend = {
                        type:'chat',
                        time:(new Date()).getTime(),
                        data:sendChat
                    }
                    that.websocket.Send(simpleSend);
                }
            });
        },
         // 上传图片
        uploadFile(params) {
            const formData = new FormData(); 
            formData.append('file', params.file);
            let fileType = this.$method.isVideoFormat(params.file.name);
            // 调用封装好的上传方法，传给后台FormData
            this.$postRequest("Upload/uploadImage",formData).then((res) => {
                if(res.status == 200){
                    if(fileType == 'video'){
                        this.send(res.data.url,'video');
                    }else if(fileType == 'image'){
                        this.send(res.data.url,'image');
                    }
                }
            })
        },
        natchMsg() {
            let str = "比如‘[微笑]’asdtgsaghd[微笑]ggg";
            let newStr = str;
            this.emojis.forEach((item) => {
                console.log(str.indexOf(item.name));
                if (str.indexOf(item.name) > -1) {
                let Img = `<img src="${item.path}" style='height:10px;'>`;
                newStr = str.replaceAll(item.name, Img);
                }
            });
            console.log(newStr);
        },
        handleScroll(event) {
            const { scrollTop } = event.target;
            if (scrollTop === 0 && this.loadingOlderMessages) {
                if(this.messageType == 1){
                    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
                    if(this.userId.create_user == userInfo.user_id){
                        this.getList(this.userId.friend_user_id);
                    }else{
                        this.getList(this.userId.create_user);
                    }
                }else if(this.messageType == 2){
                    if(!this.userId.user_id){
                        this.getList(this.userId.id);
                    }else{
                        this.getList(this.userId.user_id);
                    }
                }else if(this.messageType == 3){
                    this.getList(this.userId.to_user_id);
                }else if(this.messageType == 4){
                    this.getList(this.userId.id);
                }
            }
        },
        // 获取消息列表
        getList(userId){
            this.loadingOlderMessages = false;
            let data = {
                to_user:userId,
                limit:this.form.limit,
                page:this.form.page
            }
            this.$postRequest("mini/getUserChatList",data).then((res) => {
                if(res.status == 200){
                    this.load = false;
                    this.form.page ++;
                    let data = res.data.data.reverse();
                    this.list = [...data,...this.list];
                    if(res.data.current_page < res.data.last_page){
                        this.load = true
                        this.loadingOlderMessages = true;
                    }
                    if(this.scrolls){
                        this.$nextTick(() =>{
                            if (this.$refs.chatContent) {
                                this.$refs.chatContent.scrollTop = this.$refs.chatContent.scrollHeight + 100;
                                this.scrolls = false;
                                this.scrollTop = this.$refs.chatContent.scrollHeight;
                            }
                        })
                    }
                    else{
                        this.$nextTick(() =>{
                            if (this.$refs.chatContent) {
                                this.$refs.chatContent.scrollTop = this.scrollTop;
                                this.scrolls = false;
                            }
                        })
                    }
                    
                }
            });
        },
        // 页面滚动
        scrollToBottom() {
            if(!this.loadingOlderMessages){
                this.$nextTick(() =>{
                    if (this.$refs.chatContent) {
                        this.$refs.chatContent.scrollTop = this.$refs.chatContent.scrollHeight;
                    }
                })
            }
        }
    },
    created() {
        // this.emojiMaplist=emoji[0]['children'];
    },
    beforeMount() {},
    mounted() {
        let that = this;
        setTimeout(()=>{
            if(that.userId){
                Bus.$on('updateContacts',(res)=>{
                    let data = res.data;
                    let id;
                    if(that.messageType == 1){
                        let userInfo = JSON.parse(localStorage.getItem('userInfo'));
                        if(that.userId.create_user == userInfo.user_id){
                            id = that.userId.friend_user_id;
                        }else{
                            id = that.userId.create_user;
                        }
                    }else if(that.messageType == 2){
                        if(!that.userId.user_id){
                            id = that.userId.id;
                        }else{
                            id = that.userId.user_id;
                        }
                    }else if(that.messageType == 3){
                        id = that.userId.to_user_id;
                    }else if(that.messageType == 4){
                        id = that.userId.id;
                    }
                    console.log(that.messageType,id,that.messageType)
                    if(data.sendid == id){
                        let strObj = {
                            content:data.content,
                            from_user:{
                                id:data.from_user,
                                avatar:data.avatar,
                                nickname:data.nickname,
                            },
                            type:data.type,
                            create_time:data.createtime
                        }
                        that.list.push(strObj);
                        this.$postRequest("Mini/upSemmage",{to_user:id}).then((res) => {
                            
                        });
                        that.$nextTick(() =>{
                            if (that.$refs.chatContent) {
                                that.$refs.chatContent.scrollTop = that.$refs.chatContent.scrollHeight;
                                that.scrollTop = that.$refs.chatContent.scrollHeight;
                            }
                        })
                    }
                    
                })
            }
        },200)
    },
    beforeUpdate() {

    },
    updated() {
        this.scrollToBottom();
    },
    beforeDestroy() {
       
    }, 
    destroyed() {

    }, 
    activated() {

    }, 
    deactivated() {
        
    }, 
    };
</script>
<style lang="less" scoped>
    .loading{
        display: flex;
        align-items: center;
    }
    ::v-deep.el-button--primary{
        background-color: transparent !important;
        border: none;
        color: #333333;
        margin: 0 auto;
    }
    img {
        max-width: 100%;
        height: auto;
    }
    video{
        max-width: 100%;
        height: auto;
    }
    .el-dialog__wrapper{
        background: none;
        display: flex;
        align-items: center;
        justify-content: center;
        .el-dialog__header{
            height: 0;
            padding: 0;
        }
        .el-dialog__body{
            padding: 0;
        }
        .el-dialog{
            border-radius: 10px;
            overflow: hidden;
            margin-top: 0 !important;
        }
    }
    .direct{
        cursor: pointer; /* 小手样式 */
    }
    .directBox{
        width: 638px;
        border-radius: 5px;
        background: #FFFFFF;
        .popTit{ 
            width: 638px;
            height: 62px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            color: #333333;
            border-radius: 5px 5px 0px 0px;
            background: linear-gradient(180deg, #FFD4EB 0%, #FFD4EB 100%);
            position: relative;
            .btnBox{
            position: absolute;
            right: 0;
            height: 62px;
            display: flex;
            align-items: center;
            }
        }
    }
    .message{
        padding: 20px 0 20px;
        .messageList{
            .centenrText{
                margin: 15px 0;
            }
            .time{
                text-align: center;
                font-size: 14px;
                color: #999999;
            }
            .Left{
                display: flex;
                margin-top: 20px;
                .content{
                    margin-left: 8px;
                    .info{
                        border-radius: 5px 10px 10px 10px;
                        background: #F4F4F4;
                    }
                }
            }
            .Right{
                display: flex;
                justify-content: flex-end;
                margin-top: 20px;
                .content{
                    margin-right: 8px;
                    .contentTit{
                        justify-content: flex-end;
                    }
                    .infoBox{
                        display: flex;
                        justify-content: flex-end;
                    }
                    .info{
                        border-radius: 10px 5px 10px 10px;
                        color: #FFFFFF;
                        background: linear-gradient(180deg, #FF6FBC 0%, #D773F1 100%);
                    }
                }
            }
            .head{
                width: 35px;
                height: 35px;
                border-radius: 5px;
                overflow: hidden;
                img{
                    width: 35px;
                    height: 35px;
                    object-fit: cover;
                }
            }
            .content{
                
                .contentTit{
                    display: flex;
                    align-items: center;
                    color: #333333;
                    font-size: 14px;
                    p:nth-child(2){
                        color: #999999;
                        margin-left: 10px;
                    }
                }
                .infoBox{
                    display: flex;
                    align-items: flex-end;
                }
                .info{
                    margin-top: 6px;
                    font-size: 14px;
                    color: #333333;
                    display: inline-block;
                    max-width: 480px;
                    p{
                        padding: 8px 10px;
                        // text-align: right;
                    }
                    .infoPic{
                        display: flex;
                        flex-wrap: wrap;
                        .pic{
                            width: 120px;
                            // height: 120px;
                            border-radius: 5px;
                            margin: 10px;
                            overflow: hidden;
                        }
                        .audio{
                            height: 20px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            img{
                                width: 20px;
                            }
                        }
                    }
                }
            }
            
        }
    }
    .messageBox{
        height: 580px;
        margin: 0 36px 0;
        overflow-y: scroll;
        overflow: auto;
    }
    .review{
        position: relative;
        margin: 20px auto 0;
        width: 596px;
        min-height: 62px;
        border-radius: 15px;
        padding: 8px 15px;
        box-sizing: border-box;
        display: flex;
        align-items: flex-end;
        background: linear-gradient(180deg, #FFD4EB 0%, #FFD4EB 100%);
        .controls{
            height: 46px;
            display: flex;
            align-items: center;
            margin-right: 20px;
            .uploadBox{
                flex-shrink: 0;
                width: 30px;
                height: 30px;
                margin-right: 20px;
                ::v-deep .el-upload{
                    img{
                        width: 30px !important;
                    }
                }
            }
        }
        .searchBtn{
            width: 80px;
            height: 46px;
            font-size: 15px;
            color: #FFFFFF;
            border-radius: 0px 25px 25px 0px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        ::v-deep .el-input{
            height: 46px;
            border-radius: 25px;
            background: #FFFFFF;
            overflow: hidden;
            border: none;
            box-sizing: border-box;
            .el-input__inner{
                height: 46px !important;
                border: none;
                font-size: 15px;
                color: #333333;
                background: #FFFFFF !important;
            }
            .el-input-group__prepend{
                background: #FFFFFF !important;
                border: none;
                padding: 0 !important;
            }
            .el-input-group__append{
                border: none;
                padding: 0;
            }
        }
    }
    .inputBox{
        display: flex;
        align-items: flex-end;
        min-height: 46px;
    }
    .emojiBox{
        height: 180px;
        display: flex;
        flex-wrap: wrap;
        align-content: start;
        position: absolute;
        left: 0;
        top: -200px;
        background-color: #fff;
        overflow-y: scroll;
        .emojiList{
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            margin:5px;
        }
    }
    .textBox{
        width: 596px;
        height: 72px;
        position: absolute;
        left: 0;
        top: -82px;
        background: linear-gradient(180deg, #FFD4EB 0%, #FFD4EB 100%);
        overflow-x: scroll;
        display: flex;
        align-items: center;
        border-radius: 15px;
        padding: 10px 10px 0;
        box-sizing: border-box;
        .text{
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            height: 46px;
            border-radius: 23px;
            background: #FFFFFF;
            padding: 0 15px;
            margin-left: 15px;
        }
        img{
            width: 20px;
            height: 20px;
            margin-left: 10px;
        }
    }
    .emojiTextbox{
        min-height: 46px;
        overflow-y: scroll;
        display: flex;
        align-items: center;
        background-color: #fff;
        border-radius: 23px 0 0 23px;
        #emojiText{
            width: 390px;
            border-radius: 23px 0 0 23px;
            border: none;
            outline: none;
            box-sizing: border-box;
            // display: flex;
            // align-items: center;
            // flex-wrap: wrap;
            // align-content: flex-start;
            // img{
            //     display: inline-block;
            // }

        }
        input{
            height: 46px;
            width: 280px;
            border-radius: 23px 0 0 23px;
            border: none;
            outline: none;
            padding: 0 15px;
            font-size: 16px;
        }
        .emojiText{
            padding-left: 20px;
            box-sizing: border-box;
        }
    }
    .videoBig{
        ::v-deep .el-dialog{
            background: none;
            box-shadow: none;
            border-radius: 0px;
        }
    }
    </style>